<template>
  <!-- 購入済み-->

  <!-- 友達にシェア-->

  <!-- check friendship, follow button -->
  <v-container class="pa-7">
    <h3>お支払いが失敗しました</h3>
    <p>公式アカウントにてご相談をお承ります</p>
  </v-container>
</template>

<script>
  export default {
    name: 'Error',

    data: () => ({}),
    created() {
      // retreive order id
    },
  };
</script>
